.App {
  text-align: center;
}


.nav-logo {
  padding-left: 1.5em;
}


@keyframes play {
  100% {
    background-position: -6400px;
  }
}

body{
  background: black;
  color: white;
  margin: 0;
  min-height: 100%;
}

#root{
  background-image: radial-gradient(circle farthest-corner at -25% -50%, #0d1117 36%, rgba(13, 17, 23, 0) 48%), radial-gradient(circle farthest-corner at -25% 150%, #0d1117 45%, rgba(13, 17, 23, 0) 89%), radial-gradient(circle farthest-corner at -25% -100%, rgba(13, 17, 23, 0) 55%, rgba(13, 17, 23, 0.28) 65%, rgba(13, 17, 23, 0) 68%), radial-gradient(circle farthest-corner at -33% -75%, #1f00cc 48%, rgba(131, 5, 49, 0) 56%), radial-gradient(circle farthest-side at 0% -50%, rgba(13, 17, 23, 0) 64%, rgba(1, 2, 36, 0.4) 69%, rgba(13, 17, 23, 0) 81%), radial-gradient(circle farthest-corner at 0% -50%, rgba(13, 17, 23, 0) 33%, #010c5f 51%, rgba(13, 17, 23, 0) 72%);
  background-repeat: no-repeat;
  background-size: auto;
  margin: 0;
  min-height: 100vh;
}

#logo-center{
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  margin: 0 auto;
  max-width: 400px;
  padding-top:25vh;
  padding-bottom: 3em;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  #logo-center{
    width: 80vw;
}
}

/* Small devices (portrait tablets and large phones, 600px and up) */
/* @media only screen and (min-width: 600px) {...} */

/* Medium devices (landscape tablets, 768px and up) */
/* @media only screen and (min-width: 768px) {...} */

/* Large devices (laptops/desktops, 992px and up) */
/* @media only screen and (min-width: 992px) {...} */

/* Extra large devices (large laptops and desktops, 1200px and up) */
/* @media only screen and (min-width: 1200px) {...} */

.content-centered {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin: 0px auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.login-button {
  height: auto;
  border-radius: 20px;
  background-color: transparent;
  background-image: linear-gradient(120deg, #ff4713 28%, #fdb825);
  -webkit-transition: none 0ms ease;
  transition: none 0ms ease;
  color: white;
  font-size: 1em;
  position: relative;
  z-index: 5;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  padding: 0.75em 1.25em;
  margin: .5em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 9999px;
  background-color: #0052cc;
  -webkit-transition: color 200ms cubic-bezier(.165, .84, .44, 1);
  transition: color 200ms cubic-bezier(.165, .84, .44, 1);
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 1.125em;
  line-height: 1;
  font-weight: 600;
  text-align: center;
  letter-spacing: -0.01em;
}

/* headings */
h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.01em;
  line-height: 1.2em;
}

h1 {
  font-size: 4.6rem;
}

h2 {
  font-size: 3.6rem;
}

h3 {
  font-size: 2.6rem;
}

h4 {
  font-size: 1.7rem;
}

p {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  line-height: 1.6em;
  font-size: 1rem;
}

/* unvisited link */
a:link {
  text-decoration: none; 
  color: #a32662;
}

/* visited link */
a:visited {
  text-decoration: none; 
  color: #a32662;
}

/* mouse over link */
a:hover {
  text-decoration: none; 
  color: #fdb825 !important;
}

/* selected link */
a:active {
  text-decoration: none; 
  color: #a32662;
}